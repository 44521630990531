<template>
  <v-select
    :value="value"
    :items="producerSelectList"
    item-text="display"
    item-value="value"
    label="Select Producer"
    single-line
    @change="valueChanged"
    style="width: 150px"
    ref="selector"
    dense
    hide-details
  >
    <template v-slot:item="{ item }">
      <div>
        <div>
          {{ item.display }}
        </div>
      </div>
    </template>
  </v-select>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: ['commission'],
  data() {
    return {
      value: null,
    }
  },
  methods: {
    ...mapActions({
      setAssignment: 'commissions/unmatched/setAssignment',
    }),
    valueChanged(value) {
      this.setAssignment({
        commissionId: this.commission.id,
        assignment: value,
      })
    },
  },
  computed: {
    ...mapGetters({
      producers: 'commissions/producers/getProducers',
      assignments: 'commissions/unmatched/getAssignments',
    }),
    producerSelectList() {
      return this.producers.map(producer => {
        return {
          value: producer.id,
          display: producer.first_name + ' ' + producer.last_name,
        }
      })
    },
  },
}
</script>

<style></style>
