<template>
  <div class="text-right">
    <v-btn color="primary" @click.prevent="approve" :disabled="processing.approve || safeToApprove === false">
      Approve + Next
      <v-icon size="20" right>arrow_forward</v-icon>
    </v-btn>
    <br />
    <small class="ba-danger--text" v-if="safeToApprove === false">
      Incomplete commissions below
    </small>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as EVENTS from '@/config/globals/trackingEvents'

export default {
  props: [],
  data() {
    return {
      processing: {
        approve: false,
      },
    }
  },
  methods: {
    approve() {
      if (this.processing.approve || this.safeToApprove === false) {
        return
      }

      this.processing.approve = true

      this.updateAssignments().then(() => {
        this.$emit('approved')
      })
    },
    ...mapActions({
      updateAssignments: 'commissions/unmatched/updateAssignments',
    }),
  },
  computed: {
    ...mapGetters({
      commissionSetup: 'commissions/getCommissionSetup',
      producers: 'commissions/producers/getProducers',
      commissions: 'commissions/unmatched/getCommissions',
      assignments: 'commissions/unmatched/getAssignments',
    }),
    safeToApprove() {
      if (Object.values(this.assignments).length === 0) {
        return true
      }

      return Object.values(this.assignments).includes(null) === false
    },
  },
}
</script>

<style></style>
